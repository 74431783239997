// vocab.js
import React from "react";
import { useEffect, useState, useRef } from "react";

// import components
import Nav from './nav.js';

// import stylesheets
import './flash.css';

import FormActions from "../js/formActions.js";

function Flash(props) {

    var formActions = new FormActions();
    var [ flashWords, setFlashWords ] = useState([]);

    // fetch 
    useEffect(
        () => {
            // init login requirement to false
            props.setModalControls({
                "visible": false, 
                "type": undefined,
                "closable": false
            })

            // get jwt from cookie data
            var vocabResults = formActions.getFlashVocabs(null, setFlashWords);

            if(vocabResults != true) {
                // load login Modal
                props.setModalControls({ 
                    ...props.modalControls,
                    "visible": true, 
                    "type": "loginPrompt"
                });
            }
        },
        []
    );

    function removeFlashCard(translation_id) {
        var newFlashWords = []
        for(let key in flashWords) {
            if (translation_id != flashWords[key].id) {
                newFlashWords.push(flashWords[key]);
            }
        }

        setFlashWords(newFlashWords);
    }

    function updateFlashInput(translation_id) {
        var newFlashWords = []
        for(let key in flashWords) {

            if (translation_id == flashWords[key].id) {
                flashWords[key].guessResult = 0;
            }

            newFlashWords.push(flashWords[key]);
        }

        setFlashWords(newFlashWords);
    }

    function guessTranslation(event) {
        formActions.guessTranslation(event, removeFlashCard, updateFlashInput);
    }

    return (
        <>
            <section className="flashSection">
                <h3 role="pageHeader">Unlearned words</h3>
                {
                    flashWords.map((word) => {
                        return (
                            <form key={word.id} className="flashCard" action="/api/guessTranslation" method="POST" onSubmit={guessTranslation}>
                                <span className="fromTran">{word.wordResult.word}</span>
                                <input name="word_id" value={word.id} type="hidden"></input>
                                <input name="word" value={word.wordResult.word} type="hidden"></input>
                                <input name="lang" value={word.wordResult.lang} type="hidden"></input>
                                <input name="tran_lang" value={word.wordResult.tran_lang} type="hidden"></input>
                                <div className="translationGuess">
                                    <input type="textarea" id={"tran_input_" + word.id} className={word.guessResult == 0 ? "attemptCapture attemptFailure" : "attemptCapture attemptSuccess" } name="guessTranslation" placeholder={word.wordResult.to_lang + "\n Translation"}/>
                                    <input type="submit" className="translationSubmit" value="Guess" />
                                </div>
                            </form>
                        )
                    })
                }
            </section>
        </>
    );
}

export default Flash;