// utils.js
import axios from 'axios';
import Utilities from './utils';

class FormActions {

    login(event = null, callback) {

        if (event != null) {
            event.preventDefault();

            const utils = new Utilities();
            const formData = new FormData(event.target);

            let data = {};
            for (const pair of formData.entries()) {
                if (pair[0] == "password") {
                    data[pair[0]] = btoa(pair[1]);
                } else {
                    data[pair[0]] = pair[1];
                }
            }

            var csrfToken = utils.parseCookie('csrftoken');

            // login ajax
            const headers = {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': csrfToken
            }
            axios.post(
                "/api/login",
                data,
                { headers}
            ).then(function(response) {
                console.log("response");
                console.log(response.data.result);
                if(response.data.success == true && response.data.results > 0) {
                    // reload page
                    window.location.href = "/account";
                } else {
                    if (response.data.result.code != undefined) {
                        if(response.data.result.code == 2) {
                            utils.NotifyUser(3, "Account Authentication Needed. Please check your email for authenication.");
                        }
                    } else {
                        utils.NotifyUser(1, "Login Failed: Try again!");
                    }
                }
                
            });
        } else {

            const utils = new Utilities();

            var jwtCookie = utils.parseCookie("JWT");
            var csrfToken = utils.parseCookie('csrftoken');

            const headers = {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': csrfToken
              }
            axios.post( "/api/login", 
              { JWT: jwtCookie }, 
              { headers }
            ).then((response) => {
                console.log("response");
                console.log(response.data.result);
                if(response.data.success == true || response.data.results > 0) {
                    callback(response.data);
                } else {
                    // delete JWT cookie
                    utils.deleteCookie("JWT");
                }
            });
        }

    }

    signup(event = null, callback) {

        if (event != null) {
            event.preventDefault();

            const utils = new Utilities();
            const formData = new FormData(event.target);

            let data = {};
            for (const pair of formData.entries()) {
                if (pair[0] == "password") {
                    data[pair[0]] = btoa(pair[1]);
                } else {
                    data[pair[0]] = pair[1];
                }
            }

            var csrfToken = utils.parseCookie('csrftoken');

            // login ajax
            const headers = {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': csrfToken
            }
            axios.post(
                "/api/signup",
                data,
                { headers}
            ).then(function(response) {
                if(response.data.success == true && response.data.results > 0) {
                    // reload page
                    //window.location.href = "/account"; 

                    // delete potential JWT
                    utils.deleteCookie("JWT");

                    // notify user that they should receive an authentication email to login
                    utils.NotifyUser(2, "Sign Up Success: An Authenitcation email will ben sent to you!");

                    // redirect user to login after 3 seconds
                    setTimeout(
                        function() {
                            window.location.href = "/login"; 
                        },
                        3000
                    );
                } else {
                    // delete JWT cookie
                    utils.deleteCookie("JWT");

                    utils.NotifyUser(1, "Sign Up Failed: Try again!");
                }
                
            });
        }

    }

    logout() {

        const utils = new Utilities();

        var csrfToken = utils.parseCookie('csrftoken');
        var jwtCookie = utils.parseCookie("JWT");

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
          }
        axios.post(
            "/api/logout",
            {
                "JWT": jwtCookie
            },
            { headers}
        ).then(function(response) {

            // delete JWT cookie
            utils.deleteCookie("JWT");

            // direct user to logout page
            window.location.href = "/login";
            
        });
    }

    updateAccount(event) {
        event.preventDefault();

        const utils = new Utilities();
        const formData = new FormData(event.target);

        let data = {};
        for(const pair of formData.entries()) {
            if (pair[0] == "password") {
                data[pair[0]] = btoa(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        // parse jwt 
        data["JWT"] = utils.parseCookie("JWT");

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
          }
        axios.post(
            "/api/accountUpdate",
            data,
            { headers}
        ).then(function(response) {

            if(response.data.success == true && response.data.results > 0) {
                // reload page
                window.location.reload(); 
            } else {
                utils.NotifyUser(1, "Error Updating Account Details.");
            }
            
        });
    }

    passwordUpdate(event) {
        event.preventDefault();

        const utils = new Utilities();
        const formData = new FormData(event.target);

        let data = {};
        for(const pair of formData.entries()) {
            if (pair[0] == "password") {
                data[pair[0]] = btoa(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
          }
        axios.post(
            "/api/passwordUpdate",
            data,
            { headers}
        ).then(function(response) {

            if(response.data.success == true && response.data.results > 0) {

                utils.NotifyUser(2, "Password Reset Successful...rerouting to login.");

                setTimeout(function() {
                    // reroute user to login page
                    window.location.href = "/login";
                }, 3000);

            } else {
                utils.NotifyUser(1, "Error Updating Account Password.");
            }
            
        });
    }

    submitTranslation(event, modalControlFunction, setTranslationFunc) {
        event.preventDefault();
        
        const utils = new Utilities();
        const formData = new FormData(event.target);

        let data = {}
        for (const pair of formData.entries()) {
            if (pair[0] == "password") {
                data[pair[0]] = btoa(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        // parse jwt 
        data["JWT"] = utils.parseCookie("JWT");

        // check if JWT is present
        if (data["JWT"] == undefined) {
            // set modal controls for login prompt
            modalControlFunction({ 
                "visible": true, 
                "type": "loginPrompt"
            });

            // exit process
            return false;
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // submit translation request
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
          }
        axios.post(
            "/api/translate",
            data,
            { headers }
        ).then(function(response) {
            if(response.data.success == true || response.data.results > 0) {
                // check if the results are an error
                if (response.data.result instanceof Array && response.data.result.length > 0) {
                    console.log(response.data.result);
                    // set translation results
                    setTranslationFunc(response.data.result);

                } else {
                    // prompt user of error
                    modalControlFunction({
                        "visible": true,
                        "type": "learningPrompt"
                    });
                }
            }
        });

        return true;
    }

    getVocabs(event = null, callback) {
        const utils = new Utilities();

        let data = {};

        // parse jwt 
        data["JWT"] = utils.parseCookie("JWT");

        // if JWT not present
        if (data["JWT"] == undefined) {
            return false;
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
        }

        axios.post(
            "/api/get",
            data,
            { headers }
        ).then(function(response) {
            if(response.data.success == true && response.data.results > 0) {
                callback(response.data.result);
            } else {
                utils.NotifyUser(1, "Error fetching vocab words.");
            }
        });

        return true;
    }

    getFlashVocabs(event = null, callback) {
        const utils = new Utilities();

        let data = {};

        // parse jwt 
        data["JWT"] = utils.parseCookie("JWT");

        // if JWT not present
        if (data["JWT"] == undefined) {
            return false;
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
        }

        axios.post(
            "/api/getFlash",
            data,
            { headers }
        ).then(function(response) {
            if(response.data.success == true) {
                callback(response.data.result);
            } else {
                utils.NotifyUser(1, "Error fetching vocab words.");
            }
        });

        return true;
    }

    guessTranslation(event, removeFlashCard, updateFlashInput) {
        event.preventDefault();

        const utils = new Utilities();

        const formData = new FormData(event.target);

        let data = {}
        for (const pair of formData.entries()) {
            if (pair[0] == "password") {
                data[pair[0]] = btoa(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        // parse jwt 
        data["JWT"] = utils.parseCookie("JWT");

        // if JWT not present
        if (data["JWT"] == undefined) {
            window.location.href = "/login";
            return;
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
        }

        axios.post(
            "/api/guessTranslation",
            data,
            { headers }
        ).then(function(response) {
            if(response.data.success == true && response.data.results > 0) {

                if(response.data.result.success == true) {
                    removeFlashCard(response.data.result.translation_id);
                } else {
                    updateFlashInput(response.data.result.translation_id);
                }

            } else {
                utils.NotifyUser(1, "Error processing flashcard.");
            }
        });
    }

    recoverPassword(event) {
        event.preventDefault();

        const utils = new Utilities();
        const formData = new FormData(event.target);

        let data = {};
        for (const pair of formData.entries()) {
            if (pair[0] == "password") {
                data[pair[0]] = btoa(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        var csrfToken = utils.parseCookie('csrftoken');

        // login ajax
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
        }

        axios.post(
            "/api/emailRecovery",
            data,
            { headers}
        ).then(function(response) {
            if(response.data.success == true && response.data.results > 0) {
                // reload page
                //window.location.href = "/login";

                utils.NotifyUser(2, "Password recovery email sent!"); 
            } else {
                utils.NotifyUser(1, "Password recovery failed!");  
            }
            
        });
    }
}

export default FormActions;

