// Home.js
import React, { useEffect, useState } from 'react';
import Translator from './translator.js';
import axios from 'axios';

function Home(props) {

    const [languages, setLanguages] = useState([{
        "id": 1,
        "language": "english",
        "lang_abr": "en"
    },
    {
        "id": 2,
        "language": "spanish",
        "lang_abr": "es"
    }]);

    useEffect(() => {
        // set default login prompt to false
        props.setModalControls({
            "successful": false,
            "results": 0,
            "result": "No User Info"
          });

        axios.get( "/api/languages").then((response) => {
            if(response.data.success == true || response.data.results > 0) {
                setLanguages(response.data.result);
            }
        });
    }, []);

    return (
        <>
            <Translator languages={languages} setModalControls={props.setModalControls}/>
        </>
    );
}

export default Home;