// Nav.js
import "./nav.css";
import React from 'react';
import { Link } from 'react-router-dom';

import FormActions from "../js/formActions.js";

function Nav(props) {

    var logOutButton = null;
    var accountText = "Account";
    var accountLink = "/account";

    var formActions = new FormActions();

    if(props.userInfo != undefined && props.userInfo.success == true && props.userInfo.results > 0) {
        logOutButton = <li onClick={formActions.logout}>Logout</li>;
    } else {
        accountText = "Login";
        accountLink = "/login";
    }

    return (
        <>
            <nav>
                <h1>Wordie</h1>
                <ul>
                    <li><Link to="/">Translate</Link></li>
                    <li><Link to={accountLink}>{accountText}</Link></li>
                    <li><Link to="/vocab">Vocab</Link></li>
                    <li><Link to="/flash">Flash</Link></li>
                    {logOutButton}
                </ul>
            </nav>
        </>
    );
}

export default Nav;