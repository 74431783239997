// vocab.js
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

// import stylesheets
import './vocab.css';

// import utils
import FormActions from "../js/formActions.js";

function Vocab(props) {

    // init vars
    var renderedWords = null;
    var tranDesc = null;

    var formActions = new FormActions();

    var [ vocabs, setVocabs ] = useState([
        {
            id: 3,
            wordid: 2,
            attempts: 0,
            creation_date: 1728432899,
            wordResult: {
                id: 2,
                word: "hello",
                lang: "en",
                tran_word: "hola",
                tran_lang: "es",
                status: 1,
                from_lang: "english",
                to_lang: "spanish"
            }
        }
    ]);

    // fetch 
    useEffect(() => {
            // get jwt from cookie data
            var actionResults = formActions.getVocabs(null, setVocabs);

            if(actionResults != true) {
                // load login Modal
                props.setModalControls({ 
                    ...props.modalControls,
                    "visible": true, 
                    "type": "loginPrompt",
                    "closable": false
                });
            }
        },
        []
    );

    var renderedWords = null;
    var tranDesc = null;

    if(vocabs.length <= 0) {
        tranDesc = <h4 className="transaltionDescription" role="transaltionDescription">No vocab words currently</h4>;
    } else {
        tranDesc = <h4 className="transaltionDescription" role="transaltionDescription">Words translated from {vocabs[0].wordResult.from_lang[0].toUpperCase() + vocabs[0].wordResult.from_lang.slice(1)} to {vocabs[0].wordResult.to_lang[0].toUpperCase() + vocabs[0].wordResult.to_lang.slice(1)}</h4>
        renderedWords = vocabs.map((word) => {
            var newTranslationButton = null;
            if(word.wordResult.status === 1) {
                newTranslationButton = <button className="newTransBtn">New</button>
            } else if(word.wordResult.status === 0) {
                newTranslationButton = <button className="pendingTransBtn">Pending</button>
            }

            return (
                <tr className="translation" key={word.id}>
                    <td>{newTranslationButton}</td>
                    <td>{word.wordResult.word}</td>
                    <td>→</td>
                    <td>{word.wordResult.tran_word}</td>
                </tr>
            )
        });
    }


    return (
        <>
            <section className="vocabSection">
                {tranDesc}
                <table className="translationTables" role="translationTables">
                    <thead>
                        <tr>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                        </tr>
                    </thead>
                    <tbody role="renderedWords">
                        {renderedWords}
                    </tbody>
                </table>
                <Link className="translateLink" to="/">Translate More Words</Link>
            </section>
        </>
    );
}

export default Vocab;