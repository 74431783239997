
// import modules
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import components
import Nav from './components/nav.js'
import Home from './components/home.js';
import Account from './components/account.js';
import Login from './components/login.js';
import SignUp from './components/signup.js';
import Vocab from './components/vocab.js';
import Flash from './components/flash.js';
import ForgotPassword from './components/forgotPassword.js';
import PasswordReset from './components/passwordReset.js';
import Modal from './components/modal.js';

import FormActions from "./js/formActions.js";

// import style sheets
import './App.css';

function App() {

  const [userInfo , setUserInfo] = useState({
    "successful": false,
    "results": 0,
    "result": "No User Info"
  });

  // modal controls
  const [modalControls, setModalControls] = useState(
    { 
        "visible": false, 
        "type": undefined,
        "closable": false
    }
  );
  
  useEffect(() => {
    var formActions = new FormActions();

    formActions.login(null, setUserInfo);
  }, []);

  return (
    <> 
      <BrowserRouter>
        <Nav userInfo={userInfo}/>
        <main>
          <div id="NotificationZone">
            <span>Error: 12345</span>
          </div>
          <Modal modalControls={modalControls} setModalControls={setModalControls} />
          <Routes>
            <Route path="/" element={<Home fart={5} setModalControls={setModalControls} modalControls={modalControls}/>}></Route>
            <Route path="/account" element={<Account/>}></Route>
            <Route path="/login" element={<Login setModalControls={setModalControls} modalControls={modalControls}/>}></Route>
            <Route path="/signup" element={<SignUp setModalControls={setModalControls} modalControls={modalControls}/>}></Route>
            <Route path="/forgotPassword" element={<ForgotPassword/>}></Route>
            <Route path="/passwordReset" element={<PasswordReset/>}></Route>
            <Route path="/vocab" element={<Vocab setModalControls={setModalControls} modalControls={modalControls}/>} ></Route>
            <Route path="/flash" element={<Flash setModalControls={setModalControls} modalControls={modalControls}/>}></Route>
            <Route path="*" element={<Home/>}></Route>
          </Routes>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App;
