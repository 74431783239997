// Signup.js
import React, { useEffect } from 'react';
import Utilities from "../js/utils.js";
import FormActions from "../js/formActions.js";
import { Link } from 'react-router-dom';

// import stylesheets
import './signup.css';

function SignUp(props) {

    var utils = new Utilities();
    var formActions = new FormActions();
    
    var jwtCookie = utils.parseCookie("JWT");

    if(jwtCookie != null) {
        console.log("burp")
        // redirect user to login page
        window.location.href = "/account";
    }

    useEffect(
        () => {
            // set default login prompt to false
            props.setModalControls({
                "successful": false,
                "results": 0,
                "result": "No User Info"
            });
        }, 
        []
    );

    return (
        <section id="LoginSection">
            <form id="LoginForm" role="LoginForm" action="/api/login" method="POST" onSubmit={formActions.signup}>
                <h3 role="pageHeader">Sign Up</h3>
                <input className="credentials" role="usernameInput" placeholder='Username' name="username" type="text" required/>
                <input className="credentials" role="emailInput" placeholder='Email' name="email" type="text"required/>
                <input className="credentials" role="passwordInput" placeholder='Password' name="password" type="password" required/>
                <input className="credentials" role="passwordConfirmationInput"placeholder='Confirm Password' name="passwordConfrimation" type="password" required/>
                <div className="ActionButtons">
                    <input className="actionButton" type="submit" value="Sign Up"/>
                    <Link className="actionButton" to="/login">Login</Link>
                </div>
            </form>
        </section>
    );
}

export default SignUp;