// modal.js
import Utilities from '../js/utils.js';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import './modal.css';

function Modal(props) {
    const utils = new Utilities();

    var ModalContents = undefined;
    if (props.modalControls.type == "loginPrompt") {
        ModalContents = <>
            <h3 className="loginPromptHeader">Login Required</h3>
            <Link to="/login" className="promptButton">Login</Link>
            <span className='promptSpan'>Don't have an account? <Link to="/signup">Signup</Link></span>
        </>
    } else if ( props.modalControls.type == "learningPrompt") {
        ModalContents = <>
            <h3 style={{"textAlign": "center"}}>Unknown Word Translation</h3>
            <span className="loginPromptHeader">I found a few words I am trying to learn in your translation request.</span>
            <br></br>
            <Link to="/vocab" className="promptButton">View your vocab words</Link>
            <span className='modalClose' onClick={closeModal}>X</span>
        </>
    }

    function closeModal() {
        props.setModalControls({
            ...props.modalControls,
            "visible": false, 
            "type": undefined
        })
    }

    return (
        <div id="ModalBackground" role="modalBackground" style={ props.modalControls.visible == true ? {visibility: "visible"} : {visibility: "hidden"} }>
          <div className="GeneralModal">
            <div id="ModalContent" role="ModalContent">
                {ModalContents}
            </div>
            {props.modalControls.closable != true ? undefined : <span className="modalClose" role="modalClose" onClick={closeModal}>X</span>}
          </div>
        </div>
    );
}

export default Modal;