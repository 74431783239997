// forgot password screen
import Utilities from "../js/utils.js";
import FormActions from "../js/formActions.js";

import './passwordReset.css';

function PasswordReset() {

    const formActions = new FormActions();
    const utils = new Utilities();

    // get reset key parameter

    var searchData =  utils.getUrlSearchData();
    var resetKey = searchData.resetKey;

    var resetKeyInput = null;

    // check if reset key was passed
    if(resetKey != undefined && resetKey) {
        resetKeyInput = <input name="resetKey" role="resetKey" value={resetKey} type="hidden"/>
    } else {
        // redirect user to login page
        window.location.href = "/login";
    }

    return (
        <section className="passwordResetSection">
            <form className='recoveryForm' role="recoveryForm" action="/api/updatePassword" onSubmit={formActions.passwordUpdate}>
                <h5 className="passwordRecoverySubheader" role="pageHeader">Update Password</h5>
                {resetKeyInput}
                <input className="pResetInput" role="emailInput" name="email" placeholder='Email' type="email" required></input>
                <input className="pResetInput" role="passwordInput" name="password" placeholder='Password' type="password" required></input>
                <input className="pResetInput" role="passwordConfirmation" name="passwordConfirmation" placeholder='Confirm Password' type="password" required></input>
                <input className='recoverySubmit' role="recoverySubmit" type="submit" value="Update Password" />
            </form>
        </section>
    )
}

export default PasswordReset;