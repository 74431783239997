// forgot password screen
import Utilities from "../js/utils.js";
import FormActions from "../js/formActions.js";

import './forgotPassword.css';

function ForgotPassword() {

    const formActions = new FormActions();

    return (
        <section className="forgotPasswordSection">
            <form className='recoveryForm' role="recoveryForm" action="/api/emailRecovery" onSubmit={formActions.recoverPassword}>
                <h3 role="pageHeader">Password Recovery</h3>
                <h5 className="passwordRecoverySubheader" role="recoveryDirective">You will receive instructions for reseting your password via email.</h5>
                <input className="emailInput" role="emailInput" name="email" placeholder='email' required></input>
                <input className='recoverySubmit' role="recoverySubmit" type="submit" value="Submit" />
            </form>
        </section>
    )
}

export default ForgotPassword;