// utils.js
import axios from 'axios';

class Utilities {

    parseCookie(cookieKey) {
        // get jwt from cookie data
        var cookies = document.cookie.split(";");
        
        if(cookies == undefined || cookies.length <= 0) {
            return null
        }

        for(var i = 0; i < cookies.length; i++) {
            // pass if cookie is invalid
            if (cookies[i] == undefined || cookies[i].length <= 0) {
                continue;
            }

            // re for parsing first index of equal sign
            var re = /=/g;

            // remove quotes from cookie
            var tmpCookie = cookies[i].replaceAll("\"", "");

            // find first index of equal sign
            var firstIndex = re.exec(tmpCookie);

            // split string at index
            var key = tmpCookie.substring(0, firstIndex.index);
            key = key.trim();
            var value = tmpCookie.substring(firstIndex.index + 1);

            if(key != undefined && key == cookieKey && value != undefined && value.length > 0) {
                return value;
            }
        }

        return null;
    }

    deleteCookie(cookieKey) {
        document.cookie = cookieKey + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return true;
    }

    /* Notification functions */
    NotifyUser(type, contents) {

        // attempt to fetch notification dom
        const NotificationZone = document.getElementById("NotificationZone");

        if (NotificationZone == undefined) {
            return;
        }

        // determine notification background color based on type
        if (type == 3) {
            // warning
            NotificationZone.style.backgroundColor = "#F2AE30";
        } else if (type == 2) {
            // success
            NotificationZone.style.backgroundColor = "#2E5902";
            NotificationZone.style.color = "#FFFFFF";
        } else {
            // error
            NotificationZone.style.backgroundColor = "#A62B1F";
        }

        // Set contents for Notification 
        NotificationZone.innerHTML = contents;

        // start notification animation
        NotificationZone.style.animation = "tempNotification 4s linear 1";

        setTimeout(() => {
            const NotificationZone = document.getElementById("NotificationZone");
            NotificationZone.style.animation = "none";
        }, 5000);
    }

    getUrlSearchData() {
        var searchData = {};

        if (window.location.search != undefined && window.location.search.length > 0 ) {
            
            var search = window.location.search.substring(1, window.location.search.length);
            var searchArr = search.split("&");

            for (let sEntry of searchArr) {
                var entrySplit = sEntry.split("=");

                searchData[entrySplit[0]] = entrySplit[1];
            }
        }
        
        return searchData;
    }
}

export default Utilities;

