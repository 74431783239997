// Home.js
import React from 'react';
import Utilities from "../js/utils.js";
import FormActions from "../js/formActions.js";

// import stylesheets
import './account.css';

function Account() {

    var utils = new Utilities();
    var formActions = new FormActions();
    
    var jwtCookie = utils.parseCookie("JWT");

    if(jwtCookie == null) {
        // redirect user to login page
        window.location.href = "/login";
    }

    return (
        <section id="AccountSection">
            <form id="AccountForm" action="/api/accountUpdate" method="POST" onSubmit={formActions.updateAccount}>
                <h3 role="updateAccountHeader">Update Account</h3>
                <input className="credentials" role='usernameInput' placeholder='Username' name="username" type="text"/>
                <input className="credentials" role='emailInput' placeholder='Email' name="newEmail" type="text"/>
                <input className="credentials" role='passwordInput' placeholder='New Password' name="newPassword" type="password"/>
                <input className="credentials" role='passwordConfirmationInput' placeholder='Confirm New Password' name="passwordConfrimation" type="password"/>
                <div className="ActionButtons">
                    <input className="actionButton" role="updateSubmitButton" type="submit" value="Update"/>
                </div>
            </form>
        </section>
    );
}

export default Account;