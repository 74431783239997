// Login.js
import React , { useEffect } from 'react';
import Utilities from "../js/utils.js";
import FormActions from "../js/formActions.js";
import { Link } from 'react-router-dom';

// import stylesheets
import './login.css';

function Login(props) {

    var utils = new Utilities();
    var formActions = new FormActions();
    
    var jwtCookie = utils.parseCookie("JWT");

    if(jwtCookie != null) {
        // redirect user to login page
        window.location.href = "/account";
    }
    
    useEffect(
        () => {
            // set default login prompt to false
            props.setModalControls({
                "successful": false,
                "results": 0,
                "result": "No User Info"
            });
        }, 
        []
    );

    // parse potential authCode
    var authCodeInput = undefined;
    var searchData =  utils.getUrlSearchData();
    var authKey = searchData.akey;
    
    if(authKey != undefined && authKey) {
        authCodeInput = <input role='authKey' name="authKey" value={authKey} type="hidden"/>;
    }

    return (
        <section id="LoginSection">
            <form id="LoginForm" action="/api/login" method="POST" onSubmit={formActions.login}>
                <h3 role='LoginHeader'>Login</h3>
                {authCodeInput}
                <input className="credentials" role='usernameInput' placeholder='Username' name="username" type="text"/>
                <input className="credentials" role='passwordInput' placeholder='Password' name="password" type="password"/>
                {authCodeInput != undefined ? <span role="authDirective" style={{textAlign: "center", color: "#F2AE30"}}>Please Login To Authenticate Your Account</span> : undefined }
                <input className="loginButton" role="loginButton" type="submit" value={authCodeInput != undefined ? "Authenticate" : "Login"}/>
                
                {authCodeInput != undefined ? undefined : <span role="signupLabel">Dont have an account? <Link className="nonButtonLink" to="/signUp">Sign Up</Link></span> }
                <div className="AccountExtras">
                    <div className="checkboxInput">
                        <input role="keepLoggedInToggle" name="keepLoggedIn" type="checkbox"/>
                        <label role="keepLoggedInLabel" htmlFor="keepLoggedIn" >Keep me signed in</label>
                    </div>
                    <Link className="nonButtonLink" to="/forgotPassword">Forgot your password?</Link>
                </div>
            </form>
        </section>
    );
}

export default Login;