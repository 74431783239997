// translator.js
import React from "react";

// import stylesheets
import "./translator.css";
import FormActions from "../js/formActions";
import { useState } from 'react';

import Modal from './modal.js';

function Translator(props) {
    
    // init vars
    let langArr = [];
    var formActions = new FormActions;
    var transaltionResults = null

    var [ translations, setTranslations ] = useState([]);

    // add first to languages as default languages
    for(var i = 0; i < props.languages.length; i++) {
        langArr.push(
            <option key={props.languages[i].id} value={props.languages[i].lang_abr}>{props.languages[i].language[0].toUpperCase() + props.languages[i].language.slice(1)}</option>
        );
    }

    function submitTranslation(event) {
        formActions.submitTranslation(event, props.setModalControls, setTranslations);
    }

    // convert translation results for rendering
    if (translations.length > 0 ) {
        transaltionResults = translations.join(" ");
    } else {
        transaltionResults = null;
    }

    return (
        <section className="translator">
            <form id="translationData" role="translateForm" className="wordBoxForm" action="/api/vocab" onSubmit={submitTranslation} method="POST">
                <section className="translateConfig" defaultValue="orange">
                    <div role="fromLanguageConfig" className="languageConfig">
                        From
                        <select name="lang" defaultValue="en">
                            <optgroup role="fromLangConfigOptions" className="langOptions">
                                {langArr}
                            </optgroup>
                        </select>
                    </div>
                    <h3 className="configArrow">
                    →
                    </h3>
                    <div role="toLanguageConfig" className="languageConfig">
                        To
                        <select name="tran_lang" defaultValue="es">
                            <optgroup role="toLangConfigOptions" className="langOptions">
                                {langArr}
                            </optgroup>
                        </select>
                    </div>
                </section>
                <div className="wordBox">
                    <textarea className="translationWords tBox" role="translationWords" placeholder="Enter Text here to be translated..." name="words" required></textarea>
                    <input type="submit" className="translateAction" role="translationButton" value="TRANSLATE"/>
                </div>
                { transaltionResults != null ? 
                    <textarea className="translationResults tBox" placeholder="Awaiting transaltion results captain..."  value={transaltionResults} readOnly></textarea> :
                    <textarea className="translationResults tBox" placeholder="Awaiting transaltion results captain..."  readOnly></textarea> 
                }
            </form>
        </section>
    );
}

export default Translator;